<template>
  <b-card title="Gallery">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Video</span>
        </template>
        <AddVideo />
      </b-tab>
      <b-tab @click="$router.push('/add-gallery-images')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Images</span>
        </template>
        <router-view />
        <!-- <AddGallery></AddGallery> -->
      </b-tab>
      <b-tab @click="$router.push('/gallery-images')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> Images</span>
        </template>
        <router-view />
        <!-- <AddGallery></AddGallery> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'


import AddVideo from './addVideo.vue'

export default {
  components: {
 
    BCard,

    BTabs,
    BTab,
  
    AddVideo,
  },
  data() {
    return {

    }
  },
}
</script>
